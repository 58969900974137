import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../../components/Footer/Footer.css'; // Import the CSS file for Footer
import './GgpPaymentPlanOptions.css'; // Import the CSS file for this component
import Header from '../../components/Header/Header'; // Import Header
import Footer from '../../components/Footer/Footer'; // Import Footer
import MedicationManagementModal from './MedicationManagementModal'; // Import the modal
import ConfirmGgpPlanModal from './ConfirmGgpPlanModal'; // Import the confirmation modal

const GgpPaymentPlanOptions = () => {
  const { first_name, last_name, payment_plans } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage Medication Management modal visibility
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false); // State to manage confirmation modal visibility
  const [selectedPlan, setSelectedPlan] = useState(''); // State to store the selected plan

  // Mapping plans to their prices for sorting
  const planPrices = {
    'Pharmacy Preferred': 500,
    'Pharmacy Choice': 1000,
    'Athelas Preferred': 1500,
  };

  // Split the payment_plans string into an array and sort by price
  const plans = payment_plans ? payment_plans.split(',').sort((a, b) => planPrices[a] - planPrices[b]) : [];

  const handlePlanSelect = (plan) => {
    let formattedPlanTitle = '';
    let plainPlanName = '';
  
    switch(plan) {
      case 'Pharmacy Preferred':
        formattedPlanTitle = (
          <span>
            Athelas Home with CLOZARIL<br />
            <span className="ggp-payment-options-highlight copay">Co-Pay Assistance Program</span>
          </span>
        );
        plainPlanName = 'Pharmacy Preferred';
        break;
      case 'Pharmacy Choice':
        formattedPlanTitle = (
          <span>
            Athelas Home with<br />
            <span className="ggp-payment-options-highlight management">Medication Management</span>
          </span>
        );
        plainPlanName = 'Pharmacy Choice';
        break;
      case 'Athelas Preferred':
        formattedPlanTitle = (
          <span>
            Athelas Home<br />
            <span className="ggp-payment-options-highlight">without Medication Management</span>
          </span>
        );
        plainPlanName = 'Athelas Preferred';
        break;
      default:
        formattedPlanTitle = plan;
        plainPlanName = plan;
    }
  
    setSelectedPlan({ formattedTitle: formattedPlanTitle, plainName: plainPlanName });
    setIsConfirmModalOpen(true);
  };
  


  const confirmPlanSelect = () => {
    const formattedPlanChoice = encodeURIComponent(selectedPlan.plainName);
    navigate(`/ggp_patient_application/select_payment_plan/${encodeURIComponent(first_name)}/${encodeURIComponent(last_name)}/${formattedPlanChoice}`);
  };
  

  const renderPlan = (plan) => {
    switch(plan) {
      case 'Pharmacy Preferred':
        return (
          <div className="ggp-payment-options-plan" key={plan}>
            <h3 className="ggp-payment-options-title">
              Athelas Home with CLOZARIL <span className="ggp-payment-options-highlight copay">Co-Pay Assistance Program</span>
            </h3>
            <div className="ggp-payment-options-pricing">
              <span className="ggp-payment-options-price">$500</span>
              <span className="ggp-payment-options-per"> Athelas Home Device</span>
            </div>
            <div className="ggp-payment-options-pricing-strip">
              <span className="ggp-payment-options-strip-price">$5</span>
              <span className="ggp-payment-options-per"> per Athelas WBC Test Strip <span className="ggp-payment-options-subtle-text">(10 per box)</span></span>
            </div>
            <span className="ggp-payment-options-subtle-text">+ shipping</span>
            <div className="ggp-payment-options-refill-info">
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Fill medications with Golden Gate Pharmacy Services</span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">$0 Clozaril Co-Pays* (specific rules apply)</span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">
                  Medication Management and Synchronization
                  <button onClick={() => setIsModalOpen(true)} style={{ marginLeft: '0px', fontSize: '12px', color: '#007bff', background: 'none', border: 'none', cursor: 'pointer' }}>
                    Learn More
                  </button>
                </span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Complimentary Pill Packing Services once dosage is stabilized</span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Complimentary 2-day FedEx delivery for all medications</span>
              </div>
            </div>
            <div className="ggp-payment-options-footer">
              <button 
                onClick={() => handlePlanSelect('Pharmacy Preferred')}
                className="ggp-payment-options-select-button"
              >
                Select Service Plan
                <span className="ggp-payment-options-button-icon">→</span>
              </button>
            </div>
          </div>
        );
      case 'Pharmacy Choice':
        return (
          <div className="ggp-payment-options-plan" key={plan}>
            <h3 className="ggp-payment-options-title">
              Athelas Home with <span className="ggp-payment-options-highlight management">Medication Management</span>
            </h3>
            <div className="ggp-payment-options-pricing">
              <span className="ggp-payment-options-price">$1000</span>
              <span className="ggp-payment-options-per"> Athelas Home Device</span>
            </div>
            <div className="ggp-payment-options-pricing-strip">
              <span className="ggp-payment-options-strip-price">$5</span>
              <span className="ggp-payment-options-per"> per Athelas WBC Test Strip <span className="ggp-payment-options-subtle-text">(10 per box)</span></span>
            </div>
            <span className="ggp-payment-options-subtle-text">+ shipping</span>
            <div className="ggp-payment-options-refill-info">
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Fill medications with Golden Gate Pharmacy Services</span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">
                  Medication Management and Synchronization
                  <button onClick={() => setIsModalOpen(true)} style={{ marginLeft: '0px', fontSize: '12px', color: '#007bff', background: 'none', border: 'none', cursor: 'pointer' }}>
                    Learn More
                  </button>
                </span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Complimentary Pill Packing Services once dosage is stabilized</span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Complimentary 2-day FedEx delivery for all medications</span>
              </div>
            </div>
            <div className="ggp-payment-options-footer">
              <button 
                onClick={() => handlePlanSelect('Pharmacy Choice')}
                className="ggp-payment-options-select-button"
              >
                Select Service Plan
                <span className="ggp-payment-options-button-icon">→</span>
              </button>
            </div>
          </div>
        );
      case 'Athelas Preferred':
        return (
          <div className="ggp-payment-options-plan" key={plan}>
            <h3 className="ggp-payment-options-title">
              Athelas Home without Medication Management
            </h3>
            <div className="ggp-payment-options-pricing">
              <span className="ggp-payment-options-price">$1500</span>
              <span className="ggp-payment-options-per"> Athelas Home Device</span>
            </div>
            <div className="ggp-payment-options-pricing-strip">
              <span className="ggp-payment-options-strip-price">$20</span>
              <span className="ggp-payment-options-per"> per Athelas WBC Test Strip <span className="ggp-payment-options-subtle-text">(10 per box)</span></span>
            </div>
            <span className="ggp-payment-options-subtle-text">+ shipping</span>
            <div className="ggp-payment-options-refill-info">
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Fill medication with the pharmacy of your choice</span>
              </div>
            </div>
            <div className="ggp-payment-options-footer">
              <button 
                onClick={() => handlePlanSelect('Athelas Preferred')}
                className="ggp-payment-options-select-button"
              >
                Select Service Plan
                <span className="ggp-payment-options-button-icon">→</span>
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="ggp-payment-options-container">
      <Header /> {/* Add Header */}
      <div className="ggp-payment-options-content">
        <h2 className="ggp-payment-options-header">Your Plan Options</h2>
        <div className="ggp-payment-options-plans">
          {plans.map(plan => renderPlan(plan))}
        </div>
        <div className="ggp-payment-options-note">
          <p>Please note: This selection is only for choosing your plan. No payment will be processed at this stage.</p>
        </div>
      </div>
      <MedicationManagementModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      />
      <ConfirmGgpPlanModal
        isOpen={isConfirmModalOpen}
        onConfirm={confirmPlanSelect}
        onCancel={() => setIsConfirmModalOpen(false)}
        planTitle={selectedPlan.formattedTitle} // Use formattedTitle for display
        />
    </div>
  );
};

export default GgpPaymentPlanOptions;
