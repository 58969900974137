import React from 'react'
import './Footer.css'
import logo from '../../logo_white.svg';
import fb from './fb.svg';
import twitter from './twitter.svg';
import linkedin from './linked_in.svg';


export default class Footer extends React.Component {

    render() {
        return (
            <div class="footer container-fluid mt-5">
                <div class="row footer-row pt-5">
                    <div class="col text-left footer-logo">
                        <img src={logo} alt="logo" />
                    </div>
                    <div class="col text-center footer-logo-mobile">
                        <img src={logo} alt="logo" />
                    </div>
                    <div class="col mb-3 text-right footer-links">
                        <a class="footer-link" href="https://athelas.com/about" style={{ color: "white" }}>About Us</a>
                        <a class="footer-link ml-4" href="https://athelas.com/fda" style={{ color: "white" }}>FDA</a>
                    </div>
                </div>
                <div class="row footer-row pt-5">
                    <div class="col footer-social-media text-left">
                        <a href="https://www.facebook.com/AthelasDevice/">
                            <img src={fb} alt="facebook" />
                        </a>
                        <a class = "footer-img" href="https://twitter.com/AthelasDevice">
                            <img class="ml-4" src={twitter} alt="twitter" />
                        </a>
                        <a href="https://www.linkedin.com/company/athelas/">
                            <img class="ml-4" src={linkedin} alt="linkedin" />
                        </a>
                    </div>
                    <div class="col footer-social-media-mobile text-center">
                        <a href="https://www.facebook.com/AthelasDevice/">
                            <img src={fb} alt="facebook" />
                        </a>
                        <a class = "footer-img" href="https://twitter.com/AthelasDevice">
                            <img class="ml-4" src={twitter} alt="twitter" />
                        </a>
                        <a href="https://www.linkedin.com/company/athelas/">
                            <img class="ml-4" src={linkedin} alt="linkedin" />
                        </a>
                    </div>
                </div>
                <div class="row footer-row footer-mobile pt-5">
                    <div class="col footer-break-mobile">
                    </div>
                </div>
                <div class="row footer-row footer-mobile pt-5">
                    <div class="col text-center footer-links-mobile">
                        <a class="footer-link" href="https://athelas.com/about" style={{ color: "white" }}>About Us</a>
                        <a class="footer-link ml-4" href="https://athelas.com/fda" style={{ color: "white" }}>FDA</a>
                    </div>
                </div>
            </div>

        );
    }

}