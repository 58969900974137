import React, { Component } from "react";
import { updatePatientInfo } from "./PatientInfoSlice";
import { connect } from "react-redux";
import { withRouter } from "../../wrappers/withRouter";
import { verifyShippingAddress } from "./AddressAPI";
import _ from "lodash";
import "./PatientInfo.css";
import Modal from "react-bootstrap/Modal";
import { PatientConsent } from "../../components/patientConsent/PatientConsent";
import styled from "styled-components";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import InputMask from "react-input-mask";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const mapDispatch = { updatePatientInfo };

class PatientInfo extends Component {
  constructor(ownProps) {
    super(ownProps);
    const errors = {};
    const error_message = "";
    _.forEach(ownProps.patient, (val, key) => {
      errors[key] = false;
    });
    this.state = {
      showModal: ownProps.showModal,
      patient: ownProps.patient,
      errors,
      error_message,
    };
  }

  hasError(key) {
    return this.state.errors[key];
  }

  verifyAddress = (e) => {
    const { patient } = this.state;
    e.preventDefault();
    verifyShippingAddress(
      patient.patient_id,
      patient.address_line1 ?? "",
      patient.address_line2 ?? "",
      patient.city_locality ?? "",
      patient.state_province ?? "",
      patient.postal_code ?? ""
    )
      .then((resp) => {
        this.updatePatientInfo(resp.data);
      })
      .catch((error) => {
        this.updatePatientInfo({});
        console.log("Failed to verify address", error);
      });
  };

  updatePatientInfo = (resp) => {
    const { patient } = this.state;
    //VALIDATE
    const errors = {};
    let error_message = "";
    let hasError = false;
    let missingFields = [];
    _.forEach(patient, (value, key) => {
      if (key === "address_line2") {
        return;
      }
      if (key === "points") {
        return;
      }
      if (key === "address_verified") {
        return;
      }
      if (key === "patient_id") {
        return;
      }
      if (key === "email") {
        return;
      }
      if (
        !value &&
        key !== "insurance_number" &&
        key !== "insurance_company" &&
        key !== "bp_size"
      ) {
        errors[key] = true;
        hasError = true;
        missingFields.push(key);
        error_message = `Please complete all highlighted fields. Missing field(s): ${missingFields.join(
          ", "
        )}`;
      }
    });

    // We can't ship to PO Boxes
    const address_keys = ["address_line1", "address_line2"];
    address_keys.map((key) => {
      let value = patient[key];
      if (
        value &&
        (value.toLowerCase().includes("p o box") ||
          value.toLowerCase().includes("po box") ||
          value.toLowerCase().includes("p.o. box") ||
          value.toLowerCase().includes("p. o.  box"))
      ) {
        errors[key] = true;
        hasError = true;
        error_message =
          "Unfortunately Athelas cannot ship devices to a PO Box, please provide a residential or business address";
      }
      return null;
    });

    const address_keys_full = [
      "address_line1",
      "address_line2",
      "city_locality",
      "state_province",
      "postal_code",
    ];
    if (resp !== {}) {
      if (resp["address_verification"]["status"] !== "verified") {
        address_keys_full.map((key) => {
          errors[key] = true;
          hasError = true;
          error_message =
            "Unfortunately we cannot verify your address, please make sure your address is entered correctly and try again!";
          return null;
        });
      }
    }

    this.setState({
      errors,
      error_message,
    });

    if (!hasError) {
      this.props
        .updatePatientInfo(patient)
        .catch(() => {
          this.props.router.navigate("/failure");
        })
        .then(() => {
          if (this.props.doctor_onboarding) {
            this.props.router.navigate("/confirm?doc=true");
          } else {
            this.props.router.navigate("/additionalQuestions");
          }
        });
    }
  };

  setInsuranceCompany = (insurance_company) => {
    this.setState({
      patient: {
        ...this.state.patient,
        insurance_company,
      },
    });
  };

  setInsuranceNumber = (insurance_number) => {
    this.setState({
      patient: {
        ...this.state.patient,
        insurance_number,
      },
    });
  };

  setShippingAddress = (shipping_address) => {
    this.setState({
      patient: {
        ...this.state.patient,
        shipping_address,
      },
    });
  };

  setAddressLine1 = (address_line1) => {
    this.setState({
      patient: {
        ...this.state.patient,
        address_line1,
      },
    });
  };

  setAddressLine2 = (address_line2) => {
    this.setState({
      patient: {
        ...this.state.patient,
        address_line2,
      },
    });
  };

  setCity = (city_locality) => {
    this.setState({
      patient: {
        ...this.state.patient,
        city_locality,
      },
    });
  };

  setStateProvince = (state_province) => {
    this.setState({
      patient: {
        ...this.state.patient,
        state_province,
      },
    });
  };

  setPostalCode = (postal_code) => {
    this.setState({
      patient: {
        ...this.state.patient,
        postal_code,
      },
    });
  };

  setPhoneNumber = (phone_number) => {
    this.setState({
      patient: {
        ...this.state.patient,
        phone_number,
      },
    });
  };

  setEmail = (email) => {
    this.setState({
      patient: {
        ...this.state.patient,
        email,
      },
    });
  };

  setAcknowledged = (acknowledged, acknowledged_by) => {
    this.setState({
      patient: {
        ...this.state.patient,
        acknowledged,
        acknowledged_by,
      },
    });
  };

  setShowModal = (showFlag) => {
    this.setState({
      showModal: showFlag,
    });
  };

  setBpSize = (bp_size) => {
    this.setState({
      patient: {
        ...this.state.patient,
        bp_size,
      },
    });
  };

  showConsentModal = (e) => {
    e.preventDefault();
    this.setState({
      showModal: true,
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getAcknowledgement = () => {
    //Either the doctor is enrolling a patient on their behalf
    //or the patient is enrolling themselves
    const { patient } = this.state;
    if (this.props.doctor_onboarding) {
      return (
        <div class="form-check px-5 pt-4 input-group-lg">
          <input
            className={
              this.hasError("acknowledged")
                ? "form-check-input is-invalid"
                : "form-check-input"
            }
            type="checkbox"
            id="defaultCheck1"
            checked={patient.acknowledged}
            onChange={(e) => this.setAcknowledged(e.target.checked, "DOCTOR")}
          />
          <label class="form-check-label text-left" for="defaultCheck1">
            By clicking 'Submit,' I, {patient.prescriber_name}, attest that I
            verbally informed the patient regarding the specifics of remote
            monitoring services provided by Athelas, that RPM is postal intended
            to prevent emergency or acute health events and that they may cancel
            or unenroll at any time. The patient acknowledged and verbally
            consented to receive the services.
          </label>
        </div>
      );
    } else {
      return (
        <div class="form-check px-5 pt-4 input-group-lg">
          <input
            className={
              this.hasError("acknowledged")
                ? "form-check-input is-invalid"
                : "form-check-input"
            }
            type="checkbox"
            id="defaultCheck1"
            checked={patient.acknowledged}
            onChange={(e) => this.setAcknowledged(e.target.checked, "PATIENT")}
          />
          <label class="form-check-label text-left" for="defaultCheck1">
            By clicking 'Submit,' I, {patient.first_name} {patient.last_name},
            consent to receive remote monitoring services provided by Athelas
            under the direction and supervision of my healthcare provider. I
            consent to the terms set forth in the{" "}
            <a href="https://drive.google.com/file/d/1_ujVn3csDd6tnZ2aYe3dEIQ5oQkL9g3A/view?usp=sharing">
              patient consent
            </a>
            . Depending on your insurance, there may be a monthly copay
            associated with enrollment in this program.
          </label>
        </div>
      );
    }
  };

  getInformationMessage = () => {
    const { patient } = this.state;
    if (this.props.doctor_onboarding) {
      return (
        "We've located " +
        patient.first_name +
        " " +
        patient.last_name +
        "'s information in our database. Please review and update the below insurance and shipping information we have on file in case it's changed, and then click 'submit' to finalize enrollment."
      );
    } else {
      return (
        patient.first_name +
        ", please complete this form to be enrolled in the Athelas Remote Patient Monitoring program! This form is in compliance with HIPAA, and your data will be protected."
      );
    }
  };

  render() {
    const { patient, showModal, error_message } = this.state;
    return (
      <StyledContainer>
        {/* patient constent modal */}
        <Modal
          show={showModal}
          onHide={() => this.setShowModal(false)}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div class="p-2">
              <PatientConsent />
            </div>
          </Modal.Body>
        </Modal>

        <ConfirmInformationHeader>
          <h1> Confirm Information </h1>
        </ConfirmInformationHeader>
        {_.isEmpty(patient) ? (
          <div class="px-5 pb-3">
            <p> Loading... </p>
          </div>
        ) : (
          <div>
            <ConfirmInformationMessage>
              <p>{this.getInformationMessage()}</p>
            </ConfirmInformationMessage>

            <form class="p3">
              <div class="px-5 input-group-lg">
                <input
                  type="text"
                  class="form-control"
                  value={patient.first_name}
                  disabled
                />
              </div>
              <div class="px-5 pt-4 input-group-lg">
                <input
                  type="text"
                  class="form-control"
                  value={patient.last_name}
                  disabled
                />
              </div>
              <div class="px-5 pt-4 input-group-lg">
                <input
                  type="date"
                  class="form-control"
                  value={patient.date_of_birth}
                  disabled
                />
              </div>
              {patient.test_types.includes("BLOOD_PRESSURE") && (
                <div>
                  <Header2>
                    <h2>Device Size Selection</h2>
                  </Header2>
                  <div class="px-5 pt-4 input-group-lg">
                    <FormControl sx={{ flex: 1, width: "100%" }}>
                      <InputLabel id="bp-size-select-label">
                        Blood Pressure/Heart Rate Cuff Size
                      </InputLabel>
                      <Select
                        id="bp-size-select"
                        labelId="bp-size-select-label"
                        label="Blood Pressure/Heart Rate Cuff Size"
                        sx={{ flex: 1 }}
                        onChange={(e) => {
                          this.setBpSize(e.target.value);
                        }}
                        value={this.state.patient.bp_size ?? "REGULAR"}
                      >
                        <MenuItem value={"SMALL"}>Small (15-24cm)</MenuItem>
                        <MenuItem value={"REGULAR"}>Regular (22-45cm)</MenuItem>
                        <MenuItem value={"XL"}>XL (22-52cm)</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}
              <Header2>
                <h2> Shipping Address </h2>
              </Header2>
              <div class="px-5 pt-4 input-group-lg">
                <input
                  type="text"
                  className={
                    this.hasError("address_line1")
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  onChange={(e) => this.setAddressLine1(e.target.value)}
                  value={patient.address_line1}
                  placeholder="Address Line 1"
                />
              </div>
              <div class="px-5 pt-4 input-group-lg">
                <input
                  type="text"
                  className={
                    this.hasError("address_line2")
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  onChange={(e) => this.setAddressLine2(e.target.value)}
                  value={patient.address_line2}
                  placeholder="Address Line 2"
                />
              </div>
              <div class="row px-5 pt-4">
                <div class="col input-group-lg">
                  <input
                    type="text"
                    className={
                      this.hasError("city_locality")
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onChange={(e) => this.setCity(e.target.value)}
                    value={patient.city_locality}
                    placeholder="City"
                  />
                </div>
                <div class="state-desktop col input-group-lg">
                  <input
                    type="text"
                    className={
                      this.hasError("state_province")
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onChange={(e) => this.setStateProvince(e.target.value)}
                    value={patient.state_province}
                    placeholder="State"
                  />
                </div>
              </div>
              <div class="state-mobile px-5 pt-4 input-group-lg">
                <input
                  type="text"
                  className={
                    this.hasError("state_province")
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  onChange={(e) => this.setStateProvince(e.target.value)}
                  value={patient.state_province}
                  placeholder="State"
                />
              </div>
              <div class="px-5 pt-4 input-group-lg">
                <input
                  type="text"
                  className={
                    this.hasError("postal_code")
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  onChange={(e) => this.setPostalCode(e.target.value)}
                  value={patient.postal_code}
                  placeholder="Postal Code"
                />
              </div>
              <Header2>
                <h2> Contact Information </h2>
              </Header2>
              <div class="px-5 pt-4 input-group input-group-lg">
                <div class="input-group-prepend">
                  <span class="input-group-text" style={{ padding: '0.7rem 1rem', fontSize: '1rem' }}>+1</span>
                </div>
                <InputMask
                  mask="999 999 9999"
                  maskChar=""
                  type="text"
                  className={
                    this.hasError("phone_number")
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  value={patient.phone_number}
                  onChange={(e) => this.setPhoneNumber(e.target.value)}
                  placeholder="Phone Number"
                />
              </div>
              <div class="px-5 pt-4 input-group-lg">
                <input
                  type="text"
                  className={
                    this.hasError("email")
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  onChange={(e) => this.setEmail(e.target.value)}
                  value={patient.email} // TODO: update this to email
                  placeholder="Email"
                />
              </div>
              <Header2>
                <h2> Acknowledgement </h2>
              </Header2>
              {this.getAcknowledgement()}
              {error_message !== "" && (
                <ConfirmInformationMessage>
                  <Alert
                    sx={{ textAlign: "left", marginTop: "16px" }}
                    severity="error"
                  >
                    <AlertTitle>Invalid Information</AlertTitle>
                    {error_message}
                  </Alert>
                </ConfirmInformationMessage>
              )}
              <ButtonDiv>
                <button
                  type="submit"
                  onClick={this.verifyAddress}
                  class="btn light-btn"
                >
                  Submit
                </button>
              </ButtonDiv>
            </form>
          </div>
        )}
      </StyledContainer>
    );
  }
}

const StyledContainer = styled.div`
  margin: 0 auto;
  background-color: white;
  border: 2px solid #f8f8f8;
  box-sizing: border-box;
  box-shadow: 0px 25px 50px rgba(38, 73, 92, 0.15);
  border-radius: 8px;
  width: 30vw;
  min-width: 300px;
  max-width: 500px;
  margin-top: 20px;
`;

const ConfirmInformationHeader = styled.div`
  margin-top: 20px;
  padding-bottom: 12px;
`;

const ConfirmInformationMessage = styled.div`
  padding-left: 42px;
  padding-right: 42px;
  padding-bottom: 12px;
`;

const Header2 = styled.div`
  padding-top: 60px;
`;

const ButtonDiv = styled.div`
  padding-top: 24px;
  padding-bottom: 20px;
`;

export default connect(null, mapDispatch)(withRouter(PatientInfo));
