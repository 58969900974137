//eslint-disable-next-line no-unused-vars
import { object, string, boolean, InferType, mixed, array, date } from "yup";
import moment from "moment";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const requiredMessage = "This field is required."

export const ggpPatientSchema = object({
  first_name: string().required(requiredMessage),
  last_name: string().required(requiredMessage),
  date_of_birth: string()
    .required()
    .test("date_of_birth", "Please enter a valid date of birth", (value) => {
      return moment().diff(moment(value), "years") >= 18;
    }), // should not be enrolling any minors
  gender: string().oneOf(["Male", "Female", "Other"]).required("Please select one."),
  race: string().required("Please select one."),
  phone_number: string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required(
      "Patient phone number is required, please use cell number over landline"
    ),
  email: string().required(requiredMessage),
  address_line1: string().required(requiredMessage),
  address_line2: string().optional(),
  state_province: string().required(requiredMessage),
  city_locality: string().required(requiredMessage),
  postal_code: string().required(requiredMessage),
  country_code: string().required(requiredMessage),
  shipping_address_1: string().required(requiredMessage),
  shipping_address_2: string().optional(),
  shipping_state: string().required(requiredMessage),
  shipping_city: string().required(requiredMessage),
  shipping_postal_code: string().required(requiredMessage),
  shipping_country_code: string().required(requiredMessage),
  sections: array().of(object().shape({
    insurance_name: string().required(requiredMessage),
    insurance_type: string().required(requiredMessage),
    insurance_id: string().required(requiredMessage),
    insurance_group_number: string().required(requiredMessage),
    insurance_pcn_number: string().required(requiredMessage),
    insurance_phone_number: string().required(requiredMessage),
    })),
  doctor_first_name: string().required(requiredMessage),
  doctor_last_name : string().required(requiredMessage),
  doctor_phone_number: string().required(requiredMessage),
  pharmacy_name: string().required(requiredMessage),
  pharmacy_phone_number: string().required(requiredMessage),
  allergies_list: string().optional(),
  hippa_file: mixed().when("hippa", {
    is: "YES_HIPPA",
    then: mixed().required("Please select a file"),
  }),
  rep_first_name: string().when("hippa", {
    is: "NO_HIPPA",
    then: string().required(requiredMessage),
  }),
  rep_last_name: string().when("hippa", {
    is: "NO_HIPPA",
    then: string().required(requiredMessage),
  }),  
  rep_capacity: string().when("hippa", {
    is: "NO_HIPPA",
    then: string().required(requiredMessage),
  }),
  rep_address_1: string().when("hippa", {
    is: "NO_HIPPA",
    then: string().required(requiredMessage),
  }),
  rep_state_province: string().when("hippa", {
    is: "NO_HIPPA",
    then: string().required(requiredMessage),
  }),
  rep_city_locality: string().when("hippa", {
    is: "NO_HIPPA",
    then: string().required(requiredMessage),
  }),
  rep_postal_code: string().when("hippa", {
    is: "NO_HIPPA",
    then: string().required(requiredMessage),
  }),
  hippa_signature: string().when("hippa", {
    is: "NO_HIPPA",
    then: string().required(requiredMessage),
  }),
  rep_authority_explanation: string().when("hippa", {
    is: "NO_HIPPA",
    then: string().required(requiredMessage),
  }),
  effective_until_date: date()
  .when("watchEffectivePeriod", {
    is: "EFFECTIVE_PERIOD",
    then: date().required("Please select an effective until date"),
  }),
  patient_name: string().required(requiredMessage),
  relationship_type: string().required(requiredMessage),
  personal_signature: string().required(requiredMessage),
  referral_type: string().required(requiredMessage),
});

// parse and assert validity
// const patient = await patientSchema.validate(await fetchUser());

export type Patient = InferType<typeof ggpPatientSchema>;
/* {
    name: string;
    age: number;
    email?: string | undefined
    website?: string | null | undefined
    createdOn: Date
  }*/
