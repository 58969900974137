import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import GgpPatientApplication from "./GgpPatientApplication";
import GgpPatientApplicationConfirmation from "./GgpPatientApplicationConfirmation";
import GgpSelectPaymentPlan from "./GgpSelectPaymentPlan"; 
import GgpPaymentPlanOptions from "./GgpPaymentPlanOptions"; 
import Footer from '../../components/Footer/Footer'; // Import Footer

function GgpPatientApplicationRouter(props) {
  let [token] = useState("");

  return (
    <Routes>
      <Route exact path="/" element={<GgpPatientApplication />} />
      <Route path="confirmation" element={<GgpPatientApplicationConfirmation token={token} />} />
      <Route
        path="select_payment_plan/:first_name/:last_name/:plan_choice"
        element={<GgpSelectPaymentPlan />}
      />
      <Route
        path="payment_plan_options/:first_name/:last_name/:payment_plans"
        element={<GgpPaymentPlanOptions />}
      />
    </Routes>
  );
}

export default GgpPatientApplicationRouter;
